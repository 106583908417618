import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
  :root {
    --black: #000000;
    --lightGrey: #FAFAFA;
    --grey: #E5E5E5;
    --darkGrey: #9e9e9e;
    --white: #FFFFFF;
    background-color:var(--lightGrey)
  }

  body{
    height:100%;
    margin: 0
  }

  html {
    height:100%;
  }
  
  #___gatsby{
    height:100%;
  }

  #gatsby-focus-wrapper{
    display: flex;
    flex-direction: column;
    min-height:100%;
  }

  
  /* width */
  ::-webkit-scrollbar {
    width: 15px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px var(--lightGrey); 
    border-radius: 0px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--black); 
    /* border-radius: 10px; */
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--darkGrey); 
  }
`

export default GlobalStyles
