import React from "react"
import styled from "styled-components"

import { PageSegment } from "./PageSegment"

import logoWhite from "../assets/images/logo-white.svg"
import facebookIconWhite from "../assets/images/facebook-icon-white.svg"
import InstagramIconWhite from "../assets/images/instagram-icon-white.svg"

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  color: var(--white);
  margin-top: 25px;
  margin-bottom: 25px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 2fr;
  }
`

const FooterTextContainer = styled.div`
  padding-top: 2em;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;

  h1 {
    width: 100%;
    font-weight: 500;
    border-bottom: 2px solid var(--white);
    padding-bottom: 15px;
  }

  p {
    width: 100%;
    line-height: 2em;
    margin: 0px;
  }
  img {
    display: none;
  }
  @media (min-width: 768px) {
    grid-column: 2/3;
    width: 65%;
    justify-self: end;
    align-items: flex-start;

    h1 {
      text-align: start;
    }
    p {
      width: 65%;
    }
    img {
      padding: 5px;
      display: initial;
    }
  }
`

const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-bottom: 25px;

  img {
    padding: 5px;
  }
  @media (min-width: 768px) {
    display: none;
  }
`

const Logo = styled.div`
  img {
    display: none;
  }

  @media (min-width: 768px) {
    grid-column: 1/2;
    grid-row: 1/2;
    align-self: center;
    img {
      display: initial;
    }
  }
`

const Filler = styled.div`
  background-color: var(--black);
  flex: 1 1 auto;
`

const Icon = styled.a`
  width: 45px;
  height: 45px;

  img {
    width: 45px;
    height: 45px;
  }
`

export const Footer = () => {
  return (
    <>
      <PageSegment background="var(--black)">
        <Grid>
          <FooterTextContainer>
            <h1>INFORMATION</h1>
            <p>Contact</p>
            <p>Blog and Social Networks Policy</p>
            <p>Blog</p>
            <p>Privacy Policy and Legal Notice</p>
            <div>
              <img src={facebookIconWhite} alt="facebook" />
              <img src={InstagramIconWhite} alt="instagram" />
            </div>
          </FooterTextContainer>
          <IconContainer>
            <div>
              <img src={logoWhite} alt="logo" />
            </div>
            <div>
              <Icon href="https://www.facebook.com/Creativehub301-112130233983072">
                <img src={facebookIconWhite} alt="facebook" />
              </Icon>
              <Icon href="https://www.instagram.com/creativehub_301/">
                <img src={InstagramIconWhite} alt="instagram" />
              </Icon>
            </div>
          </IconContainer>
          <Logo>
            <img src={logoWhite} alt="logo" />
          </Logo>
        </Grid>
      </PageSegment>
      <Filler />
    </>
  )
}
