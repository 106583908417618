import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
import { Link } from "gatsby"
import useClickAway from "react-use/lib/useClickAway"

// Assets
import hamburgerMenuIcon from "../assets/images/hamburger-menu-icon.svg"
import logo from "../assets/images/logo.svg"
import facebookIcon from "../assets/images/facebook-icon.svg"
import instagramIcon from "../assets/images/instagram-icon.svg"

const NavStyled = styled.div<{ top: string }>`
  position: fixed;
  width: 100%;
  z-index: 1100;
  top: ${props => props.top};
  transition: top 0.3s;
`

const LogoBar = styled.div`
  background-color: var(--lightGrey);
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 7em;

  .logo {
    grid-column: 2 / 3;
    justify-self: center;
    align-self: center;
    height: 5em;
  }

  .button {
    grid-column: 1 / 2;
    align-self: center;
    background-color: var(--lightGrey);
    border: none;
    outline: none;
    cursor: pointer;
    width: 4em;
    height: 4em;
    margin-left: 10px;

    &:hover {
      background-color: var(--grey);
    }
    &:focus {
      background-color: var(--lightGrey);
    }
  }

  @media (min-width: 768px) {
    height: 220px;
    .logo {
      height: 155px;
    }
    .button {
      visibility: hidden;
    }
  }
`

const MenuSmall = styled(motion.ul)`
  margin: 0;
  padding: 0;
  text-align: center;
  list-style: none;

  @media (min-width: 768px) {
    display: none;
  }

  li {
    --menuElementHeight: 3em;
    width: 100%;
    background-color: var(--lightGrey);
    height: var(--menuElementHeight);
    line-height: var(--menuElementHeight);
    border-top: 2px solid var(--grey);
    &:hover {
      background-color: var(--grey);
    }
  }
  a {
    text-decoration: none;
    display: block;
    color: var(--black);
    font-weight: 700;
  }
`

const BlackBar = styled.div`
  width: 100%;
  height: 54px;
  background-color: var(--black);
  display: none;

  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    img {
      height: 40px;
    }
  }
`

const Icon = styled.a`
  width: 40px;
  height: 40px;

  img {
    width: 40px;
    height: 40px;
  }
`

const Menu = styled.ul`
  width: 100%;
  height: 78px;
  display: none;
  margin: 0;
  padding: 0;
  text-align: center;
  list-style: none;
  background-color: var(--white);

  li {
    margin: 10px;
    height: 100%;
    line-height: 78px;
    padding: 0 10px;
    &:hover {
      background-color: var(--grey);
    }
  }

  a {
    height: 100%;
    text-decoration: none;
    color: var(--black);
    font-size: 1.4em;
    display: block;
    font-weight: 700;
  }

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const TopSpacer = styled.div`
  height: 7em;
  width: 100%;
  @media (min-width: 768px) {
    height: 352px;
  }
`

const links = [
  { name: "Home", slug: "/" },
  { name: "Creatives", slug: "/creatives" },
  { name: "Our Story", slug: "/our-story" },
  { name: "301 Blog", slug: "/301-blog" },
  { name: "Contact", slug: "/contact" },
]

export const Nav = () => {
  const [openMenu, setOpenMenu] = useState(false)
  const [top, setTop] = useState("-7em")
  const ref = useRef(null)

  useClickAway(ref, () => {
    setOpenMenu(false)
  })

  useEffect(() => {
    setTop("0em")
    return () => {}
  }, [])

  return (
    <>
      <nav>
        <NavStyled top={top} ref={ref}>
          <BlackBar>
            <Icon href="https://www.facebook.com/Creativehub301-112130233983072">
              <img src={facebookIcon} alt="facebook-creative-hub-301" />
            </Icon>
            <Icon href="https://www.instagram.com/creativehub_301/">
              <img src={instagramIcon} alt="instagram-creative-hub-301" />
            </Icon>
          </BlackBar>
          <LogoBar>
            <button
              className="button"
              onClick={() => {
                setOpenMenu(openMenu => !openMenu)
              }}
            >
              <img src={hamburgerMenuIcon} alt="logo-creative-hub-301" />
            </button>
            <img src={logo} alt="logo-creative-hub-301" className="logo" />
          </LogoBar>
          <AnimatePresence>
            {openMenu && (
              <MenuSmall
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                {links.map(link => (
                  <li key={link.slug}>
                    <Link
                      to={link.slug}
                      onClick={() => {
                        setOpenMenu(false)
                      }}
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </MenuSmall>
            )}
          </AnimatePresence>
          <Menu>
            {links.map(link => (
              <li key={link.slug}>
                <Link to={link.slug}>{link.name}</Link>
              </li>
            ))}
          </Menu>
        </NavStyled>
        <TopSpacer />
      </nav>
    </>
  )
}
