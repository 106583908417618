import { createGlobalStyle } from "styled-components"

const Typography = createGlobalStyle`

  html {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--black);
    
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: "Work Sans",sans-serif;
    font-weight: normal;
    margin: 0;
  }
`

export default Typography
