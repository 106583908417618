import React from "react"
import styled from "styled-components"

const FlexContainer = styled.div<{ background: string }>`

  display: flex;
  /* display: -webkit-box; OLD - iOS 6-, Safari 3.1-6 */
  justify-content: center;
  width: 100%;
  background: ${props => props.background};

  .container {
    width: 100%;
    max-width: 1440px;

  }
`

type Props = {
  children: React.ReactNode
  background?: string
}

export const PageSegment = ({ children, background = "none" }: Props) => {
  return (
    <FlexContainer background={background}>
      <div className="container">{children}</div>
    </FlexContainer>
  )
}
